
import { mapGetters } from 'vuex'
import { Navigation } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
SwiperCore.use([Navigation])

export default {
    name: 'CustomFooter',
    components: {
        Swiper,
        SwiperSlide,
    },
    mixins: [cms, playGames],
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            footerLogo: 'cms/footerLogo',
            footerPartnerIcon: 'cms/footerPartnerIcon',
            footerArticle4: 'cms/footerArticle4',
            footerGamingInfo: 'cms/footerGamingInfo',
            footerTermAndCondition: 'cms/footerTermAndCondition',
            detailArticlesTheme3: 'cms/detailArticlesTheme3',
        }),
    },
    methods: {
        gotoDetail(item) {
            if (item?.items?.length > 0) {
                this.$router.push(
                    `/detail?component=${item?.items[0]?.component_content_id}&article=${item?.items[0]?._id}`
                )
            } else {
                this.$router.push('/detail')
            }
        },
        onNext() {
            const elParent = document.getElementsByClassName('w-partner-slider')
            if (!elParent || !elParent[0]) return
            const elNext =
                elParent[0].getElementsByClassName('swiper-button-next')
            if (!elNext || !elNext[0]) return
            elNext[0].dispatchEvent(new MouseEvent('click'))
        },
        onBack() {
            const elParent = document.getElementsByClassName('w-partner-slider')
            if (!elParent || !elParent[0]) return
            const elBack =
                elParent[0].getElementsByClassName('swiper-button-prev') //
            if (!elBack || !elBack[0]) return
            elBack[0].dispatchEvent(new MouseEvent('click'))
        },
    },
}
